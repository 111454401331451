/**
 * Almost all of our helper functions goes here
 * Style helpers go to ./style.js
 */

// import { applyLang, applyLangSingle } from './intl'

// extract nodes from edges, if no data returns empty array like in rest
// (graphql returns null if nothing is found in db...)
export const extractNodes = data => (data && data.edges && data.edges.map(e => e.node)) || []

// extract all edges - nodes data from all data results from DB / graphql
export const extractNodesFromData = propsData => {
  const transformed = { ...propsData }
  Object.keys(propsData).forEach(key => {
    // some data does not have to have edges, skip them
    if (propsData[key].edges) {
      transformed[key] = propsData[key].edges.map(e => e.node)
    }
  })

  return transformed
}

// extract all edges - nodes data from all data results from DB / graphql
// AND apply lang extractions
// export const extractNodesFromDataApplyLangs = (propsData, lang) => {
//   if (!lang) {
//     console.error('pozor, pri extrakci neni nastaven jazyk!')
//   }
//   const transformed = { ...propsData }
//   Object.keys(propsData).forEach(key => {
//     // if its single model only
//     if (typeof propsData[key] === 'object' && propsData[key].modelName) {
//       transformed[key] = applyLangSingle(propsData[key], lang)
//     }
//     // some data does not have to have edges, skip them
//     else if (propsData[key].edges) {
//       transformed[key] = applyLang(propsData[key].edges.map(e => e.node), lang)
//     }
//   })

//   return transformed
// }

export const withSameTags = (arrayToFilter = [], tags = []) => arrayToFilter.filter(
  p => p.tags && p.tags.some(tag => tags && tags.find(tag2 => tag2._id === tag._id)),
)
